const ANIMATION_TIMING = {
    duration: 300,
    easing: 'ease-out',
};

class Accordion {
    /**
     *
     * @param {HTMLElement} element
     */
    constructor(element) {
        this.details = element;
        this.summary = element.querySelector('summary');
        this.content = element.querySelector('.js-accordionItem__content');

        this.animation = null;
        this.isClosing = false;
        this.isExpanding = false;

        this.summary.addEventListener('click', (event) => this.onClick(event));
    }

    /**
     *
     * @param {Event} event
     */
    onClick(event) {
        event.preventDefault();
        this.details.setAttribute('aria-expanded', !this.details.open);

        // Check if the element is being closed or is already closed
        if (this.isClosing || !this.details.open) {
            this.open();
            // Check if the element is being opened or is already open
        } else if (this.isExpanding || this.details.open) {
            this.shrink();
        }
    }

    shrink() {
        this.isClosing = true;
        const startHeight = `${this.details.offsetHeight}px`;
        const endHeight = `${this.summary.offsetHeight}px`;
        this.details.setAttribute('aria-expanded', 'false');

        // If there is already an animation running
        if (this.animation) {
            this.animation.cancel();
        }

        this.animation = this.details.animate({height: [startHeight, endHeight]}, ANIMATION_TIMING);

        this.animation.onfinish = () => this.onAnimationFinish(false);
        this.animation.addEventListener('cancel', () => (this.isClosing = false));
    }

    open() {
        // Apply a fixed height on the element
        this.details.style.height = `${this.details.offsetHeight}px`;
        this.details.open = true;
        this.details.setAttribute('aria-expanded', 'true');
        window.requestAnimationFrame(() => this.expand());
    }

    expand() {
        this.isExpanding = true;
        const startHeight = `${this.details.offsetHeight}px`;
        // Calculate the open height of the element (summary height + content height)
        const endHeight = `${this.summary.offsetHeight + this.content.offsetHeight}px`;

        // If there is already an animation running
        if (this.animation) {
            this.animation.cancel();
        }

        this.animation = this.details.animate({height: [startHeight, endHeight]}, ANIMATION_TIMING);

        this.animation.onfinish = () => this.onAnimationFinish(true);
        this.animation.addEventListener('cancel', () => (this.isExpanding = false));
    }

    onAnimationFinish(open) {
        this.details.open = open;
        // Clear the stored animation
        this.animation = null;
        this.isClosing = false;
        this.isExpanding = false;
        this.details.style.height = '';
    }
}
class AccordionList extends HTMLElement {
    constructor() {
        super();
        this.boundOnClick = this.#onClick.bind(this);  // Bind the method to this instance of the component
    }

    /** Called each time the element is added to the document. */
    connectedCallback() {
        this.addEventListener('click', this.boundOnClick);
    }

    disconnectedCallback() {
        this.removeEventListener('click', this.boundOnClick);
    }

    /**
     * @param {Event} event
     */
    #onClick(event) {
        const target = event.target;
        const summary = target.closest('summary');
        const details = target.closest('details.accordionList__item');

        if (!summary || !details) return;

        if (!details._accordion) {
            details._accordion = new Accordion(details);
            // re-trigger click event after creating Accordion object
            details._accordion.onClick(event);
        }
    }
}

customElements.define('accordion-list', AccordionList);
